@import '../../style/mixins.scss';


.prompt_wrapper{

  .prompt_content{
    z-index: 20;
    padding: 20px;
    width: 406px;
    background: white;
    position: relative;
  }
  .prompt_heading{
    position: relative;
    @include flex-set(row,flex-start,center);
  }
  .prompt_heading_line{
    @include font-set(18px, normal, 500, $main-color);
  }
  .prompt_icon{
    @include pseudo;
    @include flex-set(row,center,center);
    @include box(25px);
    background: url('/assets/img/icons/info.svg') no-repeat;
    background-size: 100% 100%;
    background-position: center;
    right: 0px;
    top: 0;
  }
  .prompt_description{
    @include font-set(18px, normal, 500,#8F8F8F);
  }
  &.hide{
    z-index: 0;
    .darked-background{
      display: none;
    }
    .prompt_description{
      display: none;
    }
  }
  &.show{
    .prompt_content{
      box-shadow: 0 0 10px 5px rgba(0,0,0,0.5);
    }
  }
}

.imei_wrapper{
  position: relative;
  .prompt_icon_wrapper{
    padding: 20px;
    position: absolute;
    right: 0px;
    top: 0%;
    height: 100%;
    display: flex;
    justify-content: center;
   align-items: center;
    .prompt_icon{
     
      height: 20px;
      width: 20px;
     
      @include pseudo;
      @include flex-set(row,center,center);
      @include box(25px);
      background: url('/assets/img/icons/info.svg') no-repeat;
      background-size: 100% 100%;
      background-position: center;
     
      
      transform: translateY(-50%);
    }
  }
 
}
