
.support_line{
    background-color: white;
    position: fixed;
    background-color: white;
    padding-bottom: 40px;
    padding-top: 10px;
    bottom: 0;
    width: 100%;
    font-size: 48px;
    font-weight: 700;
    color: gray;
    display: flex;
    justify-content: center;
}
