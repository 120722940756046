@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-100.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-100Italic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-300.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-300Italic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-500.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-500Italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-700.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-700Italic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-900.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('/assets/fonts/MuseoSansCyrl-900Italic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}