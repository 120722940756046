@import '../../style/mixins.scss';
.top_line {
    background: $second-color;
    margin: 20px 0;
    width: 100%;
    height: 80px;
    position: relative;
  
    .container {
      height: 100%;
    }
  
    .top_line_icon {
      background: $main-color;
      position: absolute;
      min-width: 100px;
      height: 100px;
      top: -10px;
      padding: 10px;
      @include flex-set(row, center, center);
  
      &.left {
        left: 20px;
      }
  
      &.right {
        right: 70px;
      }
    }
  
    .top_line_icon_content {
      @include font-set(60px, normal, 700);
    }
  
    .top_line_pic {
      @include box(60px);
    }
  
    .top_line_text {
      height: 100%;
      width: 100%;
      padding-left: 140px;
      text-align: center;
      @include flex-set(row, flex-start, center);
      @include font-set(48px, normal, 700, white);
  
      i {
        color: $main-color;
        padding: 0 10px;
      }
      &.left{
        padding-left: 50px;
      }
    }
  }