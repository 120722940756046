@import '../../style/mixins.scss';
.service_links{
    width: 100%;
    padding: 10px 0;
    position: relative;
    @include flex-set(column,center,center);
    overflow-y: hidden;
    .service_link{
        width: 88%;
        height: 130px;
        margin-top: 20px;
        padding: 0 70px;
        background: $main-color;
        @include flex-set(row,space-between,center);
    }
    .service_link_heading{
        @include font-set(48px,normal,700);
    }
    .service_link_pic{
        margin-right: 20px;
        width: 60px;
        &.reversed{
            transform: rotate(180deg);
        }
    }
    .build-version{
        @include font-set(40px,normal,300);
        padding: 30px 0;
    }
}
.service_back-button{
    margin-top: 260px;
    @include flex-set(column,center,center);
}