@import '../../style/mixins.scss';


.qrScanImage {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .col {
        width: 50%;
    }

    .primary {
        font-size: 36px;
        color: $main-color;
        height: 600px;
        font-weight: 700;
    }

    img {
        margin-left: 20px;
        margin-top: 40px;
        height: 600px;
    }
}
