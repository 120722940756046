@import '../../style/mixins.scss';
.loading{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
  
    
    @include flex-set(column,center,center);
    .loading_content{
        @include flex-set(row,center,center);
    }
    .loading_heading{
        @include font-set(52px,normal,700,white);
        text-align: center;
    }
    .loading_img{
        width: 140px;
        margin-left: 20px;
    }
}
