@import '../../style/mixins.scss';
.requisites{
    padding: 30px 70px;
    .requisites_line{
        @include flex-set(row,flex-start,center);
        margin: 10px 0;
    }
    .requisite_heading{
        @include font-set(32px,normal,700);
        width: 420px;
        position: relative;
        &:after{
            @include pseudo;
            content: ':';
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .requisite_info{
        @include font-set(32px,normal,500);
        margin-left: 20px;
    }
    .feedback-link{
        width: 80%;
        height: 100px;
        margin: 40px auto;
        padding: 0 70px;
        background: $main-color;
        @include flex-set(row,space-between,center);
    }
    .feedback-link_heading{
        @include font-set(30px,normal,700);
    }
    .feedback-link_pic{
        margin-right: 20px;
        width: 60px;
        &.reversed{
            transform: rotate(180deg);
        }
    }
}