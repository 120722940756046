@import '../../style/mixins.scss';
.footer-video_wrapper{
    width: 100%;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}
.footer-video{
    width: 100%;
    height: 400px;
}
