@import '../../style/mixins.scss';
.how-it-works_wrapper{
    @include box(100%);
    .how-it-works{
        box-shadow: 0 0 10px 10px rgba(0,0,0,0.5);
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow-y: scroll;
        padding: 40px;
        width: 80%;
        max-height: 80%;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .how-it-works-heading{
        @include font-set(60px,normal,700);
        text-align: center;
        width: 100%;
        @include flex-set(row,center,center);
        margin-bottom: 40px;
    }
    .how-it-works-block{
        @include flex-set(column);
        margin-bottom: 40px;
        &.closed{
            .how-it-works-block-description{
                overflow: hidden;
                height: 0;
                padding: 0 20px;
                opacity: 0;
            }
            .how-it-works-block-heading{
                &::after{
                    transform: rotate(90deg);
                }
            }
        }
    }
    .how-it-works-block-heading{
        background: $main-color;
        @include font-set(40px,normal,700);
        padding: 20px;
        width: 100%;
        position: relative;
        &::after{
            
            @include pseudo;
            @include box(50px);
            right: 20px;
            top: 20px;
            transform: rotate(270deg);
            background: url('/assets/img/icons/forward-button-white.svg');
            @include cover-background;
        }
    }
    .how-it-works-block-description{
        opacity: 1;
        width: 100%;
        padding: 20px;
        background: #dfdfdf;
        transition: 0.5s ease;
        height: auto;
    }
    .how-it-works-block-description-list-line{
        @include font-set(30px,normal,500);
        margin: 12px 0;
        padding-left: 40px;
        position: relative;
        @include flex-set(row,flex-start,center);
        &::before{
            @include pseudo;
            @include box(10px);
            left: 0;
            background: black;
            border-radius: 100%;
        }
    }
    .how-it-works-block-article{
        @include font-set(30px,normal,500);
    }
    .how-it-works-block-img{
        width: 100%;
        margin: 20px 0;
    }
    .how-it-works-block-bold{
        @include font-set(44px,normal,700);
    }
}