.item-list{
    .container{
        @include flex-set(row,flex-start,center);
        flex-wrap: wrap;
    }
    &.small{
        .item-card{
            @include box(320px,350px);
        }
        .item-card_pic{
            @include box(100%,160px);
        }
    }
}