@import '../../style/mixins.scss';
.order-stages{
    width: 100%;
    margin: 14px 0;
    .order-stages_list{
        @include flex-set(row,center,center);
    }
    .order-stage{
        @include flex-set(row,center,center);
        width: 25%;
        height: 80px;
        background: $second-color;
        .stage_number{
            @include font-set(60px,normal,700,white);
            margin-right: 20px;
        }
        .stage_text{
            @include font-set(20px,normal,900,white);
            max-width: 50%;
        }
        &.active{
            height: 90px;
            background: $main-color;
            .stage_number{
                color: black;
            }
            .stage_text{
                color: black;
            }
        }
    }
}