@import '../../style/mixins.scss';

.status_text{
    font-size: 24px;
}
.status-block{
    @include flex-set(row,space-between);
    align-items: center;
    .status-columns{
        padding-left: 20px;
    }
    .status-block_half{
        
        &.centered{
            @include flex-set(column,center,center);
        }
        align-items: center;
    }
    .status-block_half_link{
        @include font-set(18px,normal,500,$main-color);
        @include flex-set(row,flex-start,center);
    }
    .status-block_half_line{
        @include flex-set(row);
        padding: 6px 0;
        b{
            @include font-set(24px,normal,700);
            padding-right: 8px;
        }
        span{
            @include font-set(24px,normal,500);
        }
    }
    .status-block_half_image{
        width: 300px;
        margin-top: -110px;
    }
    .status-block_half_heading{
        @include font-set(36px,normal,700);
    }
    .price-wrapper{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        .prefix {
            color: $main-color;
            font-size: 24px;
        }
        .price {
            @include font-set(44px,normal,700);
            margin-bottom: 20px;
            font-size: 64px;
        }
       
    }
    
    .status-block_half_warning{
        @include font-set(18px,normal,500,red);
    }
    .main-button{
        margin-bottom: 20px;
    }
    .status_warning-line{
        margin: 20px 0;
        @include flex-set(row,center,center);
        position: relative;
    }

    .status_warning-line_heading{
        @include font-set(18px, normal, 500, $main-color);
    }

    .status_warning-line_icon{
        @include pseudo;
        @include flex-set(row,center,center);
        @include box(25px);
        background: url('/assets/img/icons/info.svg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        right: -40px;
    }
    .repair_title{
        font-size:36px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
.main-button{
    margin-top: 30px;
   img{
    width: 100px;
    padding: 10px;
   }
}
.pay-card{
    color: black;
}
.main-button-icons{
    display: flex;
    justify-content: space-around;
}
.pay-sbp{
    border: 5px;
    border-color: black;
    border-style: solid;
    background-color: white;
    color: black;
}
.sdb_icon{
    width: 100px;
    
}
.status-buttons{
    margin-top: 190px;
    .status-buttons_line{
        width: 100%;
        
        @include flex-set(row,center,center);
        .main-button{
            margin: 15px 0 0 15px;
        }
    }
}
