@import '../../style/mixins.scss';
.order-end_warning{
    @include flex-set(column,center,center);
    margin-bottom: 30px;
    .warning_text{
        @include box(940px,720px);
        @include font-set(18px,normal,500,$third-color);
        // @include flex-set(column,flex-start,center);
        text-align: center;
        background: rgb(243,243,243);
        padding: 40px;
        overflow-y: scroll;
        p{
            padding: 20px 0;
        }
    }
}
.order-end_agree{
    padding: 0 60px;
}
// .order-end_buttons{
//     padding: 60px 0;
//     .order-end_button_line{
//         margin: 40px 0;
//         @include flex-set(row,space-between,center);
//     }
//     .order-end_button_heading{
//         @include font-set(38px,normal,700);
//     }
//     .order-end_confirm{
//         @include flex-set(row,center,center);
//         .main-button{ margin-left: 20px}
//     }
// }
