@import '../../style/mixins.scss';
.phone_data_layout{
    display: flex;
    .input-phone{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .enter-inputs{
        padding-left: 20px;
        padding-right: 20px;
        width: 60%;
        
        .dont_know{
            margin-bottom: 38px;
        }
    }
}


.phone-data-input_checkbox{
    @include flex-set(row,center,center);
    margin: 10px 0 10px 0px;
    position: relative;
}

.phone-data-input_line {
    @include flex-set(row);
    margin: 0 50px;
    margin-right: 0px;
    .phone-data-input_wrapper {
        @include flex-set(column);
        width: 410px;
        margin-top: 20px;
    }

    .phone-data-input, .phone-data_choose-button {
        @include font-set(36px, normal, 500);
        width: 100%;
        padding: 10px 0;
        text-align: center;
        
    }

    .phone-data-input{
        border-bottom: 10px solid rgb(243, 243, 243);
        &.focused{
            border-color: $main-color;
        }
        &.disabled{
            color: $third-color;
            background: rgb(247,247,247);
            border-bottom: 10px solid rgb(243, 243, 243);
        }
    }

    .phone-data_choose-button{
        background: $main-color;
        color: white;
        &.disabled{
            color: $third-color;
            background: rgb(247,247,247);
        }
    }

    .phone-data-input::-webkit-input-placeholder {
        color: $third-color;
    }

    .phone-data-input::-moz-placeholder {
        color: $third-color;
    }

    .phone-data-input:-ms-input-placeholder {
        color: $third-color;
    }

    .phone-data-input::-ms-input-placeholder {
        color: $third-color;
    }

    .phone-data-input::placeholder {
        color: $third-color;
    }
}

.phone-data_button{
    color: white;
    background: $main-color;
}

.brand-list_wrapper{
    &.hidden{
        display: none;
    }
    .brand-list{
        width: 70%;
        position: absolute;
        background: white;
        z-index: 20;
        margin-left: 15%;
    }
    
    .brands-heading{
        @include flex-set(row,center,center);
        width: 100%;
        text-align: center;
        @include font-set(40px,normal,600);
        padding: 20px 0;
        border-bottom: 3px solid $third-color;
    }
    .brands-wrapper{
        @include flex-set(column,center,center);
    }
    .brands{
        width: 100%;
        padding: 40px 0;
    }
    .brand{
        @include font-set(40px,normal,600);
        padding: 15px 60px;
        border-bottom: 3px solid $third-color;
        height: 20%;
        &.active{
            background: $main-color;
            color: white;
        }
    }
    .brand-choose{
        @include box(50%,100%);
        @include flex-set(row,center,center);
        margin-bottom: 40px;
    }
}
.models_wrapper,.brand-list_wrapper{
    &.hidden{
        display: none;
    }
    .models-heading{
        @include flex-set(row,flex-start,center);
        width: 100%;
        text-align: center;
        @include font-set(40px,normal,600);
        padding: 60px;
       
    }
    .models-dialog{
        padding-bottom: 70px;
        // height: 620px;
        position: absolute;
        width: 950px;
        @include flex-set(column,center,flex-start);
        z-index: 20;
        margin: auto;
        display: flex;
        flex-direction: column;
        background: white;
       
        top: 355px;
        left: 50%;
        transform:  translateX(-50%);
    }
    .models{
        width: 100%;
        @include flex-set(row,center,center);
    }
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: #E4E4E4;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 0px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #FF9838;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
    .models_list{
       
        width: 50%;
        height: 400px;
        margin-bottom: 20px;
        overflow-y: scroll;
        
    }
    .models{
        @include flex-set(row,center,flex-end);
    }
    .model{
        @include font-set(32px,normal,600);
        padding: 15px;
        margin-right: 40px;
        &.active{
            background: $main-color;
            color: white;
        }
    }
    .model_pic_wrapper{
        height: 400px;
        padding: 10px;
        @include flex-set(column,center,center);
       
        justify-content: flex-end;
        
    }
    .model_pic{
        width: 340px;
        height: 100%;
        object-fit: contain;
        
    }
}

.step-list-point {
    position: relative;
    .sing {
        position: absolute;
        top: 15px;
        font-size: 100px;
    }
}

.where-is-imei{
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
    color: $main-color;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        margin-left: 10px;
    }
}
