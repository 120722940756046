@import "../../style/mixins.scss";



.pay-block {
    margin: 25px;
    margin-left: 0px;
    font-weight: 700;
    font-size: 32px;

    .pay-options {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .pay-option {
            width: 200px;
            height: 120px;
            border-style: solid;
            border-color: black;
            border-width: 2px;
            @include flex-set(row, center, center);
        }

        .sbp {}

        .card {
            margin-left: 10px;
        }
    }

}
.product-pay {
    .pay-block {
        margin-left: 0px;
       
    }
}
