@import '../../style/mixins.scss';

.header-container {
  height: 200px;
  display: flex;
  align-items: center;
}

.header_logo_text{
  font-size: 90px;
  font-weight: 800;
  line-height: 110px;
  // font-family: 'Oswald';
  -webkit-transform: scaleY(1.7);
  transform: scaleY(1.5);
  display: inline-block;
  text-align: center;
}
.header_logo{
    padding: 40px 10px;
    width: 100%;
    position: relative;
    text-align: center;
}
.header_logo_pic{
    width: 100%;
}
.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
.record-mark{
    display: flex;
    position: absolute;
    align-items: center ;
    right: 20px;
    bottom: 10px;
    .record-icon{
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            position: absolute;
        }
    }
    .record-text{
        color: #FF0000;
    }
}
