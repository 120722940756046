@import '../../style/mixins.scss';

.category_line {
    padding: 24px 0;
  
    .category_line_text {
      @include font-set(48px, normal, 700);
      padding: 0 0px;
      width: 100%;
      // &:before {
      //   @include pseudo;
      //   @include box(60px);
      // }
    }
  }
