@import '../../style/mixins.scss';

.defects{
    .defects_list{
        padding: 32px 50px;
        @include flex-set(row,space-between,center);
        flex-wrap: wrap;
    }
    .defect_checkbox{
        @include box(40px);
        margin-right: 20px;
    }
    .defect{
        @include flex-set(row,flex-start,center);
        width: 42%;
        margin-bottom: 20px;
        position: relative;
        &:before{
            @include pseudo;
            @include box(40px);
            position: absolute;
            left: 0;
            top: 0;
            border: 3px solid black;
            display: none;
        }
        &.comment{
            .defect_text{
                color: $main-color;
            }
            .defect_checkbox{
                border-color: $main-color;
            }
            &::after{
                @include pseudo;
                @include font-set(18px,normal,300,$main-color);
                bottom: -30px;
                left: 60px;
                content: '(если есть пожелания к заказу)';
            }
        }
    }
    .defect_text{
        @include font-set(24px,normal,500);
    }
   
}
.disabled{
    background-color: lightgray;
}
