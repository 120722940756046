@import '../../style/mixins.scss';
.card-methods_wrapper{
    @include flex-set(row,center,center);
    
    .payment-methods{
        height: 600px;
        width: 90%;
        @include flex-set(column,space-around,center);
        position: absolute;
        background: white;
        z-index: 20;
        box-shadow: 0 0 10px 10px rgba(0,0,0,0.5);
        padding: 20px;
        top: 35%;
        left: 50%;  
        transform: translate(-50%,-50%);  

        
        .pinpad-image{
            @include flex-set(row,center,center);
           
        }
        .payment-method-title{
            text-align: center;

            color: #000000;
            font-size: 40px;
        }
    }
    .payment-instuction{
        @include flex-set(row,space-around,center);
    }
    .methods_heading{
        @include font-set(32px,normal,600);
        margin-bottom: 30px;
    }
    .methods{
        @include flex-set(row,space-between,center);
        padding: 0 40px;
        width: 100%;
        margin-bottom: 40px;
    }
    .method{
        width: 40%;
    }
    .method_pic{
        width: 100%;
    }
    &.hidden{
        display: none
    }
    .method-description{
        @include flex-set(column,center,center);
        border: 10px solid $main-color;
        border-radius: 24px;
        margin: 40px 0;
        padding: 20px;
        &.hidden{
            display: none;
        }
    }
    .method-description-text{
        @include font-set(40px,normal,700);
        padding: 20px 0;
        text-align: center;
    }
    .method-description-pic{
        width: 400px;
    }
  }
