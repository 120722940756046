$main-color: #ff782a;
$second-color: rgb(0,0,0);
$third-color: rgb(181, 181, 181);
$gray-color:#D6D6D6;
$fourth-color: rgb(249, 249, 249);
@mixin font-set($font-size: 16px, $font-style: normal, $font-weight: normal, $font-color: black, $line-height: normal){
    font-size: $font-size;
    font-style: $font-style;
    font-weight: $font-weight;
    color: $font-color;
    line-height: $line-height;
}
@mixin flex-set($flex-direction: row, $justify-content: flex-start, $align-items: flex-start){
    display: flex;
    flex-direction: $flex-direction;
    justify-content: $justify-content;
    align-items: $align-items;
}
@mixin pseudo($content: '', $display: block, $position: absolute){
    content: $content;
    display: $display;
    position: $position;
}
@mixin box($width, $height: $width) {
    width: $width;
    height: $height;
}
@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
