@import '../../style/mixins.scss';

.order-result-wrapper{
    @include flex-set(column,center,center);
  
  
    .order-result{
        border-radius: 24px;
        width: 90%;
        @include flex-set(column,center,flex-start);
        
        .order-number{
            color: $main-color;
            font-size: 48px;
        }
    }
    .order-result-heading{
        margin-top: 40px;
        @include font-set(48px,normal,700);
    }
    .order-result-number{
        @include font-set(46px,normal,500);
        text-align: center;
        margin: 30px 0;
        b{
            font-weight: 700;
        }
    }
    .order-result-description{
        @include font-set(36px,normal,500);
       
        margin-bottom: 60px;
    }
}
