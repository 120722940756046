@import "../../style/mixins.scss";
.custom-checkbox_wrapper {
    @include flex-set(row, center, center);
    position: relative;
    height: 40px;
    background: transparent;
    .custom-checkbox {
        @include box(40px);
        min-width: 40px;
        padding: 5px;
        border: 3px solid black;
        background: none;
    }
    .checkbox_heading {
        padding-left: 20px;
        text-align: left;
        @include font-set(20px, normal, 500);
    }
    &.checked{
        .custom-checkbox {
            background-image: url('/assets/img/icons/mark2.svg');
            background-size: 100% 100%;
            background-position: center center;
        }
    }
    &.yellow{
        &.checked{
            .custom-checkbox{
                background-image: url('/assets/img/icons/mark2-yellow.svg');
            }
        }
        .checkbox_heading{
            color: $main-color;
        }
        .custom-checkbox{
            border-color: $main-color;
        }
    }
}
