@import '../../style/mixins.scss';

// .homepage-videos_wrapper{
//     @include flex-set(row,center,center);
//     @include box(100%);
//     .homepage-videos{
//         box-shadow: 0 0 10px 10px rgba(0,0,0,0.5);
//         background: white;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         z-index: 20;
//         @include box(80%, 800px);
//         @include flex-set(column,center,center);
//     }
//     .recommended-videos{
//         width: 80%;
//         @include flex-set(row,space-between);
//     }
//     .recommended-video{
//         @include box(220px);
//         img{
//             @include box(100%);
//         }
//     }
// }

.category-card{
    text-align: center;
}

.homepage-buttons{
    padding: 40px 0;
    .container{
        @include flex-set(row,space-between,center);
    }
    .homepage-button{
        @include flex-set(column);
        background: $main-color;
        text-align: left;
        padding: 30px 40px;
        @include box(49%,300px);
        position: relative;
        &.first{
            &:after{
                @include pseudo;
                @include box(200px,130px);
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background: url('/assets/img/icons/arrow-white.svg');
                @include cover-background;
            }
        }
        &.second{
            &:after{
                @include pseudo;
                @include box(200px,130px);
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background: url('/assets/img/icons/arrow-white-left.svg');
                @include cover-background;
            }
        }
    }
    .homepage-button_text{
        @include font-set(58px,normal,700);
        &:after{
            @include pseudo;
            @include box(60px,100px);
            right: 30px;
            top: 44px;
            background: url('/assets/img/icons/phone_home.svg');
            @include cover-background;
        }
    }
}

.hidden{
    display: none;
}
