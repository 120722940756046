@import '../../style/mixins.scss';
.card-methods_wrapper{
    @include flex-set(row,center,center);
    
    .saled-window{
        width: 90%;
        
        @include flex-set(row,space-around,center);
        position: absolute;
        background: white;
        z-index: 20;
        box-shadow: 0 0 10px 10px rgba(0,0,0,0.5);
        padding: 20px;
        padding-top: 80px;
        padding-bottom: 80px;
        top: 35%;
        left: 50%;  
        transform: translate(-50%,-50%);  

        .saled-value{
            font-size: 200px;
            margin-top: 70px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;


        .saled-title{
            font-size: 40px;
        }
        .sale-percent{
            font-size: 200px;
            margin-top: 70px;
            font-weight: 600;
            color: $main-color;
        }
        .saled-buttons{
            display: flex;
           
            align-items: center;
            justify-content: space-between
        }

        
        .pinpad-image{
            @include flex-set(row,center,center);
           
        }
    }
    .methods_heading{
        @include font-set(32px,normal,600);
        margin-bottom: 30px;
    }
    .methods{
        @include flex-set(row,space-between,center);
        padding: 0 40px;
        width: 100%;
        margin-bottom: 40px;
    }
    .method{
        width: 40%;
    }
    .method_pic{
        width: 100%;
    }
    &.hidden{
        display: none
    }
    .method-description{
        @include flex-set(column,center,center);
        border: 10px solid $main-color;
        border-radius: 24px;
        margin: 40px 0;
        padding: 20px;
        &.hidden{
            display: none;
        }
    }
    .method-description-text{
        @include font-set(40px,normal,700);
        padding: 20px 0;
        text-align: center;
    }
    .method-description-pic{
        width: 400px;
    }
  }
