@import './fonts.scss';
@import './reset.scss';
@import './mixins.scss';
@import './item-categories.scss';


body {
  max-width: 1080px;
  height: 1920px;
  margin: auto;
  position: relative;
  user-select: none;
}

body,
button,
a {
  font-family: 'MuseoSansCyrl';
}

.container {
  margin: auto;
  max-width: 1040px;
}

.main-title {
  text-align: center;
}

.defects_buttons {
  @include flex-set(row, center, center);
  padding: 10px;

  .main-button {
    width: 50%;
  }

  .back {

    &:before {
      background: url('/assets/nav_back.png');

      @include pseudo;
      left: 50px;
      @include box(50px);
      background-size: cover;
    }
  }

  .next {

    &:after {
      background: url('/assets/nav_next.png');
      @include pseudo;
      right: 50px;
      @include box(50px);
      background-size: cover;
    }

  }

  .disabled {
    &:after {

      opacity: 0.3;
    }
  }
}

.status_buttons {
  @include flex-set(row, center, center);
  padding: 10px;

  .main-button {
    width: 50%;
  }

  .back {

    &:before {
      background: url('/assets/nav_back.png');

      @include pseudo;
      left: 50px;
      @include box(50px);
      background-size: cover;
    }
  }


  .disabled {
    &:after {

      opacity: 0.3;
    }
  }
}

.main-button {
  @include flex-set(row, center, center);
  @include font-set(30px, normal, 700, white);
  @include box(368px, 80px);
  min-height: 80px;
  text-align: center;
  margin: 0 10px;

  &.yellow {
    background: $main-color;
    // color: black;
  }

  &.black {
    background: $second-color;
  }

  &.gray {
    background: $third-color;
    color: black;
  }

  &.disabled {
    background-color: lightgray;
  }
}

.category_line {
  padding: 24px 0;

  .category_line_text {
    @include font-set(48px, normal, 700);
    padding: 0 140px;

    // &:before {
    //   @include pseudo;
    //   @include box(60px);
    //   left: 10px;
    //   background: url('/assets/img/icons/square.svg');
    // }
  }
}

.footer_service-link {
  position: absolute;
  bottom: 0;
  right: 20px;
  @include box(320px, 120px);
  background: $second-color;
  @include font-set(38px, normal, 500, white);
}

.item-card_price{
  position: relative;
  img{
      position: absolute;
      left: 10px;
      right: 30px;
      top: 30px;
      bottom: 30px;
      width: 70%;
  }
}

.qr-video{

  margin-top: 40px;
}
.finish-video{
  height: 700px;
  width: 500px;
}

.invisible {
  visibility: hidden;
}

.above-buttons-block {
  min-height: 800px;
  max-height: 800px;
  margin-top: 30px;
}

.finish-block {

  @include flex-set(column, center, center);

  .finish-block_line {
    @include flex-set(row, center, center);
    @include font-set(48px, normal, 700);
    margin: 0px 150px;
    text-align: left;
  }

  // .finish-block_img{
  //   @include flex-set(column,center,center);
  //   width: 500px;
  //   margin: 40px auto;
  // }
  .finish-block_ul {
    @include flex-set(column, center, center);
    width: 100%;
    margin: 10px auto;
  }

  .finish-block_li {
    @include font-set(40px, normal, 500);
  }

  .finish-block_video {
    @include flex-set(row, center, center);
  }
}

.finish-block_buttons {
  @include flex-set(row, flex-end);

  button {
    margin: 0 10px;
  }
}

.hidden {
  display: none;
}


.darked-background {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  @include box(100%);
  background: rgba(0, 0, 0, 0.5);
}

.image-with-steps {
  padding: 0px 20px;
  @include flex-set(row, center, flex-start);

  .image-container {
    width: 50%;
  }

  .steps-container {
    width: 50%;
  }
}

.primaryText {
  color: $main-color;
  font-weight: 800;
}

.step-list {
  .step-list-item {
    display: flex;
    align-items: center;

    .step-list-point {
      margin: 10px;
      width: 80px;
      min-width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      background-color: $main-color;
      margin-right: 30px;
    }

    .step-list-item-text {
      font-size: 32px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
